import './bootstrap';
import {ga4Push} from './helper/ga4-utilities';

class SzallasGuruApp {

    constructor() {
        this.init();
    }

    init() {
        this.initMobileMenuToggler();
        this.initPopovers();
        this.initCountdownStickyEvent();
        this.initDetailSearchEvent();
    }

    initMobileMenuToggler() {
        $('.mobile-menu-toggle').on('click', (event) => {
            event.preventDefault();
            $('.mobile-menu').toggleClass('mobile-menu--open');

            if ($('#mobileMenu').hasClass('mobile-menu--open')) {
                ga4Push({
                    event: 'menu_open',
                });
            }
        });
    }

    initPopovers() {
        Array.from($('[data-bs-toggle="popover"]')).map((popoverTriggerEl) => new global.Popover(popoverTriggerEl));
    }

    initCountdownStickyEvent() {
        if ($(document).find('.countdown').length > 0) {
            $(window).on('scroll.window load.window', () => {
                $('.countdown').checkStuck('countdown--sticky');
            });
        }
    }

    initDetailSearchEvent() {
        $('.detail-search__buttons .button-dropdown').on('hide.bs.dropdown', (event) => {
            if ($(event.currentTarget).find('input[type="checkbox"]:checked').length > 0) {
                $(event.currentTarget).addClass('detail-search--selected-button');
            }
            else {
                $(event.currentTarget).removeClass('detail-search--selected-button');
            }
        });

        $('.detail-search__buttons .detail-search__select button').on('click', (event) => {
            const dropdown = global.Dropdown.getInstance($(event.currentTarget).closest('.button-dropdown').find('.dropdown-toggle'));
            dropdown.hide();
        });
    }

}

$(() => {
    new SzallasGuruApp();
});
